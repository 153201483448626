import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"

const FaithStatementPage = () => {
  const theme = useTheme()
  const classes = makeStyles({
    centerText: {
      textAlign: "center",
    },
    page: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(10),

      "& *": {
        lineHeight: 2,
      },
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout>
      <Seo title="Faith Statement" />
      <Container maxWidth="md" disableGutters={isSmallScreen}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={isSmallScreen ? 0 : 1} className={classes.page}>
              <Typography variant="h4" align="center">
                Faith Statement
              </Typography>
              <Container maxWidth="sm">
                <Divider className={classes.divider} variant="middle" />
                <Typography variant="body1" gutterBottom>
                  We believe that God exists and that He is the Creator of all
                  things – seen and unseen. He is sovereign, eternal, almighty,
                  all knowing and all good.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We believe God has spoken and His Word is true and trustworthy
                  and that we should live in a manner that is governed by His
                  Word.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We believe that God’s love has been revealed to the world in
                  the person and work of Jesus Christ, the Son of God.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We believe Jesus Christ came to save sinners from all people,
                  tribes and tongues. Jesus Christ died and rose again on the
                  3rd day and that He is now seated in heaven at the right hand
                  of God.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We believe that God sent His Holy Spirit to indwell every
                  regenerated child of God. The Spirit of God empowers every
                  child of God to live in a manner that is pleasing to Him.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We believe that Jesus Christ will come again and that He will
                  make all things new. We have the calling to know God, believe
                  and spread His Word, and live for His glory.
                </Typography>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default FaithStatementPage
